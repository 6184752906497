@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply text-base;
  }
  h6 {
    @apply text-sm text-gray-400;
  }

  .input {
    @apply w-full h-full px-1 ring-0 outline-none text-sm font-normal;
  }
}

progress {
  /* style rules */
  border-radius: 20px;
  height: 10px;
}
progress::-webkit-progress-bar {
  /* style rules */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}
progress::-webkit-progress-value {
  /* style rules */
  background-color: #1eb12d;
  border-radius: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: none;
}


/* Spinner */
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}


input[type=number].input-number-no-control::-webkit-inner-spin-button, 
input[type=number].input-number-no-control::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number].input-number-no-control::-webkit-inner-spin-button, 
input[type=number].input-number-no-control::-webkit-outer-spin-button {
  opacity: 1;
}

.plain-modal {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
}




* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
}
